<template>
  <a-row :gutter="20">
    <a-col :span="9">
      <div class="mb-8"><a-input-search placeholder="请输入名称" /></div>
      <a-space>
        <a-button type="primary" @click="handleClickAdd">新增</a-button>
      </a-space>
      <a-list
        class="iss-list"
        row-key="id"
        :loading="loading"
        :data-source="dataSource"
      >
        <template #renderItem="{ item }">
          <a-list-item>
            <template #actions>
              <operation :options="options" :record="item" />
            </template>
            <a-list-item-meta :description="item.appDesc" :title="item.name">
              <template #avatar>
                <a-avatar
                  :class="item.status ? 'enable' : 'disable'"
                  :size="35"
                >
                  <template #icon>
                    <component :is="icons[item.icon]" />
                  </template>
                </a-avatar>
              </template>
            </a-list-item-meta>
          </a-list-item>
        </template>
      </a-list>
    </a-col>
    <a-col :span="15">
      <a-card :title="form.id ? '编辑' : '新增'" class="iss-card">
        <a-tabs size="small" v-model:activeKey="activeKey">
          <a-tab-pane tab="详情" key="one">
            <a-form
              class="iss-form"
              :label-col="{ span: 5 }"
              :wrapper-col="{ span: 16 }"
            >
              <a-form-item label="子应用标题" v-bind="validateInfos.name">
                <a-input v-model:value="form.name" />
              </a-form-item>
              <a-form-item
                label="路由名称"
                v-bind="validateInfos.applicationKey"
              >
                <a-input v-model:value="form.applicationKey" />
              </a-form-item>
              <a-form-item label="路由路径" v-bind="validateInfos.entry">
                <a-input v-model:value="form.entry" />
              </a-form-item>
              <a-form-item label="接入方式" v-bind="validateInfos.iframe">
                <a-radio-group v-model:value="form.iframe">
                  <a-radio :value="false">Qiankun</a-radio>
                  <a-radio :value="true">Iframe</a-radio>
                </a-radio-group>
              </a-form-item>
              <a-form-item label="应用地址" v-bind="validateInfos.entryUrl">
                <a-input v-model:value="form.entryUrl" />
              </a-form-item>
              <a-form-item name="icon" label="图标">
                <a-input-search
                  v-model:value="form.icon"
                  placeholder="请选择图标"
                  @search="visible = true"
                >
                  <template #enterButton>
                    <a-button>
                      <component
                        :is="icons[form.icon || 'InfoCircleOutlined']"
                      />
                    </a-button>
                  </template>
                </a-input-search>
              </a-form-item>
              <a-form-item name="status" label="状态">
                <a-switch
                  v-model:checked="form.status"
                  checked-children="启用"
                  un-checked-children="禁用"
                />
              </a-form-item>
              <a-form-item name="appDesc" label="描述">
                <a-textarea v-model:value="form.appDesc" />
              </a-form-item>
              <a-form-item
                :wrapperCol="{ offset: 5, span: 16 }"
                class="ta-right"
              >
                <a-button type="primary" @click="handleSave">保存</a-button>
              </a-form-item>
            </a-form>
          </a-tab-pane>
          <!-- <a-tab-pane tab="操作权限" key="two" :disabled="!form.id">
            Content of Tab Pane 2
          </a-tab-pane> -->
        </a-tabs>
      </a-card>
    </a-col>
  </a-row>
  <icon-modal
    v-model:visible="visible"
    :init-value="form.icon"
    @fnOk="value => (form.icon = value)"
  />
</template>

<script>
import { reactive, ref, toRaw, toRefs } from 'vue';
import { useRoute } from 'vue-router';
import {
  Avatar,
  Badge,
  Card,
  Col,
  Form,
  InputNumber,
  List,
  message,
  Popconfirm,
  Radio,
  Row,
  Space,
  Switch,
  Tabs,
} from 'ant-design-vue';
import * as icons from '@ant-design/icons-vue';
import Operation from '@/components/operation';
import IconModal from '@/components/iconModal';
import appApi from '@/api/app.js';

export default {
  components: {
    AAvatar: Avatar,
    ABadge: Badge,
    ACard: Card,
    ACol: Col,
    AForm: Form,
    AFormItem: Form.Item,
    AInputNumber: InputNumber,
    AList: List,
    AListItem: List.Item,
    AListItemMeta: List.Item.Meta,
    APopconfirm: Popconfirm,
    ARadio: Radio,
    ARadioGroup: Radio.Group,
    ARow: Row,
    ASpace: Space,
    ASwitch: Switch,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    Operation,
    IconModal,
  },
  setup() {
    const route = useRoute();
    const state = reactive({
      loading: false,
      dataSource: [],
    });
    const activeKey = ref('one');
    const visible = ref(false);
    const form = reactive({
      id: '',
      name: '',
      applicationKey: '',
      entry: '',
      iframe: false,
      icon: '',
      status: true,
      entryUrl: '',
      appDesc: '',
    });
    const required = { required: true, message: '不能为空' };
    const { validateInfos, validate, resetFields } = Form.useForm(form, {
      name: [required],
      applicationKey: [required],
      entry: [required],
      entryUrl: [required],
    });
    const initDataSource = () => {
      state.loading = true;
      appApi
        .getList(route.path)
        .then(data => {
          state.dataSource = data;
        })
        .finally(() => {
          state.loading = false;
        });
    };
    const handleClickAdd = () => {
      activeKey.value = 'one';
      state.selectedKeys = [];
      resetFields();
    };
    initDataSource();
    return {
      options: [
        {
          type: 'update',
          permission: 'app:update',
          fnClick: record => Object.assign(form, record),
        },
        {
          type: 'delete',
          permission: 'app:delete',
          fnClick: ({ id }) => {
            appApi.delete('app:delete', { ids: [id] }).then(() => {
              message.success('操作成功');
              form.id === id && resetFields();
              initDataSource();
            });
          },
        },
      ],
      icons,
      activeKey,
      visible,
      ...toRefs(state),
      form,
      validateInfos,
      resetFields,
      handleClickAdd,
      handleSave: () => {
        validate().then(() => {
          let type = 'add';
          form.id && (type = 'update');
          appApi[type](`app:${type}`, toRaw(form)).then(() => {
            message.success('操作成功');
            resetFields();
            initDataSource();
          });
        });
      },
    };
  },
};
</script>

<style lang="less" scoped>
.iss-list {
  height: calc(100vh - 186px);
  overflow-y: auto;
  .enable {
    background-color: @success-color;
  }
  .disable {
    background-color: @error-color;
  }
}
.iss-form {
  height: calc(100vh - 271px);
  overflow-y: auto;
}
</style>
